export const FETCH_STATIC_ZONES_REQUEST = "FETCH_STATIC_ZONES_REQUEST"
export const FETCH_STATIC_ZONES_SUCCESS = "FETCH_STATIC_ZONES_SUCCESS"
export const FETCH_STATIC_ZONES_FAILED = "FETCH_STATIC_ZONES_FAILED"

export const FETCH_STATIC_ZONE_REQUEST = "FETCH_STATIC_ZONE_REQUEST"
export const FETCH_STATIC_ZONE_SUCCESS = "FETCH_STATIC_ZONE_SUCCESS"
export const FETCH_STATIC_ZONE_FAILED = "FETCH_STATIC_ZONE_FAILED"

export const CREATE_STATIC_ZONE_REQUEST = "CREATE_STATIC_ZONE_REQUEST"
export const CREATE_STATIC_ZONE_SUCCESS = "CREATE_STATIC_ZONE_SUCCESS"
export const CREATE_STATIC_ZONE_FAILED = "CREATE_STATIC_ZONE_FAILED"

export const DELETE_STATIC_ZONE_REQUEST = "DELETE_STATIC_ZONE_REQUEST"
export const DELETE_STATIC_ZONE_SUCCESS = "DELETE_STATIC_ZONE_SUCCESS"
export const DELETE_STATIC_ZONE_FAILED = "DELETE_STATIC_ZONE_FAILED"

export const UPDATE_STATIC_ZONE_REQUEST = "UPDATE_STATIC_ZONE_REQUEST"
export const UPDATE_STATIC_ZONE_SUCCESS = "UPDATE_STATIC_ZONE_SUCCESS"
export const UPDATE_STATIC_ZONE_FAILED = "UPDATE_STATIC_ZONE_FAILED"

export const BULK_DELETE_STATIC_ZONE_REQUEST = "BULK_DELETE_STATIC_ZONE_REQUEST"
export const BULK_DELETE_STATIC_ZONE_SUCCESS = "BULK_DELETE_STATIC_ZONE_SUCCESS"
export const BULK_DELETE_STATIC_ZONE_FAILED = "BULK_DELETE_STATIC_ZONE_FAILED"