import store from 'store'
import ApiClient from './ApiClient';


export const fetchDevices = (params: any) => {
    return ApiClient.get('device/', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchDevice = (deviceId: Number) => {
    return ApiClient.get(`device/${deviceId}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updateDevice = (deviceId: Number, payload: any) => {
    return ApiClient.put(`device/${deviceId}`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}


export const fetchKml = (params: any) => {
    return ApiClient.get('kml/', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchReplayDates = (date: string) => {
    const companyId = store.get('companyId')
    return ApiClient.get(`heartbeat/replay/${date}/company/${companyId}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
    
}

export const fetchReplays = (start: Number, minutes: Number) => {
    return ApiClient.get(`heartbeat/replay/${start}/${minutes}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchReplayStaticDates = (date: string, deviceId: string) => {
  return ApiClient.get(`heartbeat/replay-static/${date}/${deviceId}`, {})
      .then( response => {
          if(response){
              return response.data
          }
          return false
      })
}

export const fetchStaticReplay = (start: string, end: string, deviceId: string) => {
    return ApiClient.get(`heartbeat/replay-static/${start}/${end}/${deviceId}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}