import ApiClient from './ApiClient';

export const fetchZones = (params: any) => {
    return ApiClient.get('kml/', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchZone = (id: string) => {
    return ApiClient.get(`kml/${id}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const createZone = (params: any) => {
    return ApiClient.post(`kml/`, params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updateZone = (id: string, params: any) => {
    return ApiClient.put(`kml/${id}`, params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const deleteZone = (id: string) => {
    return ApiClient.delete(`kml/${id}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const uploadKmlOrKmz = (formData: any) => {
    return ApiClient.post(`kml/kmz-upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const bulkDeleteKml = (formData: any) => {
  return ApiClient.post(`kml/bulk-delete`, formData).then( response => {
          if(response){
              return response.data
          }
          return false
      })
}