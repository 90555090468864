import {
  FETCH_DASHBOARD_STATS_REQUEST,
  FETCH_DASHBOARD_STATS_SUCCESS,
  FETCH_DASHBOARD_STATS_FAILED,
} from './types';

const initialState = {
  loading: false,
  error: null,
  dashboardStats: null
}

interface IAction {
  type: String,
  payload: any
}

const staticZonesReducer = ( state = initialState, action: IAction ) => {
  switch (action.type) {
      case FETCH_DASHBOARD_STATS_REQUEST:
          return {
              ...state,
              loading: true,
              error: null,
          }

      case FETCH_DASHBOARD_STATS_SUCCESS:
          return {
              ...state,
              loading: false,
              dashboardStats: action.payload,
          }

      case FETCH_DASHBOARD_STATS_FAILED:
          return {
              ...state,
              loading: false,
              dashboardStats: null,
              error: action.payload
          }
      default:
          return state
  }
}

export default staticZonesReducer;