export const SET_DEVICE_STATE = "SET_DEVICE_STATE"
export const FETCH_DEVICES_REQUEST = "FETCH_DEVICES_REQUEST"
export const FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS"
export const FETCH_DEVICES_FAILED = "FETCH_DEVICES_FAILED"

export const FETCH_DEVICE_REQUEST = "FETCH_DEVICE_REQUEST"
export const FETCH_DEVICE_SUCCESS = "FETCH_DEVICE_SUCCESS"
export const FETCH_DEVICE_FAILED = "FETCH_DEVICE_FAILED"

export const UPDATE_DEVICE_REQUEST = "UPDATE_DEVICE_REQUEST"
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS"
export const UPDATE_DEVICE_FAILED = "UPDATE_DEVICE_FAILED"

export const FETCH_KML_REQUEST = "FETCH_KML_REQUEST"
export const FETCH_KML_SUCCESS = "FETCH_KML_SUCCESS"
export const FETCH_KML_FAILED = "FETCH_KML_FAILED"

export const FETCH_REPLAYS_REQUEST = "FETCH_REPLAYS_REQUEST"
export const FETCH_REPLAYS_SUCCESS = "FETCH_REPLAYS_SUCCESS"
export const FETCH_REPLAYS_FAILED = "FETCH_REPLAYS_FAILED"

export const FETCH_REPLAY_DATES_REQUEST = "FETCH_REPLAY_DATES_REQUEST"
export const FETCH_REPLAY_DATES_SUCCESS = "FETCH_REPLAY_DATES_SUCCESS"
export const FETCH_REPLAY_DATES_FAILED = "FETCH_REPLAY_DATES_FAILED"

export const FETCH_REPLAY_STATIC_REQUEST = "FETCH_REPLAY_STATIC_REQUEST"
export const FETCH_REPLAY_STATIC_SUCCESS = "FETCH_REPLAY_STATIC_SUCCESS"
export const FETCH_REPLAY_STATIC_FAILED = "FETCH_REPLAY_STATIC_FAILED"

export const FETCH_REPLAY_STATIC_DATES_REQUEST = "FETCH_REPLAY_STATIC_DATES_REQUEST"
export const FETCH_REPLAY_STATIC_DATES_SUCCESS = "FETCH_REPLAY_STATIC_DATES_SUCCESS"
export const FETCH_REPLAY_STATIC_DATES_FAILED = "FETCH_REPLAY_STATIC_DATES_FAILED"