import ApiClient from './ApiClient';
import store from 'store';

export const fetchPOIs = (params: any) => {
    const workSites = store.get('workSites');
    if (workSites && workSites.length) {
        params['id'] = workSites.join();
    }
    return ApiClient.get('poi/', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchPOI = (id: string) => {
    return ApiClient.get(`poi/${id}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const createPOI = (params: any) => {
    return ApiClient.post(`poi/`, params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updatePOI = (id: string, params: any) => {
    return ApiClient.put(`poi/${id}`, params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const deletePOI = (id: string) => {
    return ApiClient.delete(`poi/${id}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}