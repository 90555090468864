export const FETCH_DEVICE_REPORTS_REQUEST = "FETCH_DEVICE_REPORTS_REQUEST"
export const FETCH_DEVICE_REPORTS_SUCCESS = "FETCH_DEVICE_REPORTS_SUCCESS"
export const FETCH_DEVICE_REPORTS_FAILED = "FETCH_DEVICE_REPORTS_FAILED"

export const GET_DEVICE_REPORT_REQUEST = "GET_DEVICE_REPORT_REQUEST"
export const GET_DEVICE_REPORT_SUCCESS = "GET_DEVICE_REPORT_SUCCESS"
export const GET_DEVICE_REPORT_FAILED = "GET_DEVICE_REPORT_FAILED"

export const GET_DEVICE_ZONE_REPORT_REQUEST = "GET_DEVICE_ZONE_REPORT_REQUEST"
export const GET_DEVICE_ZONE_REPORT_SUCCESS = "GET_DEVICE_ZONE_REPORT_SUCCESS"
export const GET_DEVICE_ZONE_REPORT_FAILED = "GET_DEVICE_ZONE_REPORT_FAILED"