import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
    FETCH_POI_LIST_REQUEST,
    FETCH_POI_LIST_SUCCESS,
    FETCH_POI_LIST_FAILED,
    FETCH_POI_REQUEST,
    FETCH_POI_SUCCESS,
    FETCH_POI_FAILED,
    CREATE_POI_REQUEST,
    CREATE_POI_SUCCESS,
    CREATE_POI_FAILED,
    UPDATE_POI_REQUEST,
    UPDATE_POI_SUCCESS,
    UPDATE_POI_FAILED,
    DELETE_POI_REQUEST,
    DELETE_POI_SUCCESS,
    DELETE_POI_FAILED
} from './types';
import {
    fetchPOIs,
    fetchPOI,
    createPOI,
    updatePOI,
    deletePOI
} from '../../services/poiService';

export function* fetchPOIsSaga({payload}: any): Generator<any> {
    try {
        const result:any = yield call(fetchPOIs, payload)
        if(result){
            yield put({ type: FETCH_POI_LIST_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_POI_LIST_FAILED, payload: error })
    }
}

export function* fetchPOISaga({payload}: any): Generator<any> {
    try {
        const { poiId } = payload;
        const result = yield call(fetchPOI, poiId)
        if(result){
            yield put({ type: FETCH_POI_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_POI_FAILED, payload: error })
    }
}

export function* createPOISaga({payload}: any): Generator<any> {
    try {
        const result = yield call(createPOI, payload)
        if(result){
            yield put({ type: CREATE_POI_SUCCESS, payload: result })
            //yield put({ type: FETCH_POI_LIST_REQUEST, payload: {} })
        }
    } catch (error) {
        yield put({ type: CREATE_POI_FAILED, payload: error })
    }
}

export function* updatePOISaga({payload}: any): Generator<any> {
    try {
        const { id, data } = payload;
        const result = yield call(updatePOI, id, data)
        if(result){
            yield put({ type: UPDATE_POI_SUCCESS, payload: result })
            //yield put({ type: FETCH_POI_LIST_REQUEST, payload: {} })
        }
    } catch (error) {
        yield put({ type: UPDATE_POI_FAILED, payload: error })
    }
}

export function* deletePOISaga({payload}: any):Generator<any> {
    try {
        const { id } = payload;
        const result = yield call(deletePOI, id)
        yield put({ type: DELETE_POI_SUCCESS, payload: result })
        yield put({ type: FETCH_POI_LIST_REQUEST, payload: {} })
    } catch (error) {
        yield put({ type: DELETE_POI_FAILED, payload: error })
    }
}

export default function* deviceSagas(){
    yield all([
        takeEvery(FETCH_POI_LIST_REQUEST, fetchPOIsSaga),
        takeEvery(FETCH_POI_REQUEST, fetchPOISaga),
        takeEvery(CREATE_POI_REQUEST, createPOISaga),
        takeEvery(UPDATE_POI_REQUEST, updatePOISaga),
        takeEvery(DELETE_POI_REQUEST, deletePOISaga),
    ])
}