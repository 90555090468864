export const FETCH_STATIC_ALERTS_REQUEST = "FETCH_STATIC_ALERTS_REQUEST"
export const FETCH_STATIC_ALERTS_SUCCESS = "FETCH_STATIC_ALERTS_SUCCESS"
export const FETCH_STATIC_ALERTS_FAILED = "FETCH_STATIC_ALERTS_FAILED"

export const FETCH_STATIC_ALERT_REQUEST = "FETCH_STATIC_ALERT_REQUEST"
export const FETCH_STATIC_ALERT_SUCCESS = "FETCH_STATIC_ALERT_SUCCESS"
export const FETCH_STATIC_ALERT_FAILED = "FETCH_STATIC_ALERT_FAILED"

export const FETCH_DEVICE_ALERTS_REQUEST = "FETCH_DEVICE_ALERTS_REQUEST"
export const FETCH_DEVICE_ALERTS_SUCCESS = "FETCH_DEVICE_ALERTS_SUCCESS"
export const FETCH_DEVICE_ALERTS_FAILED = "FETCH_DEVICE_ALERTS_FAILED"

export const FETCH_DEVICE_ALERT_REQUEST = "FETCH_DEVICE_ALERT_REQUEST"
export const FETCH_DEVICE_ALERT_SUCCESS = "FETCH_DEVICE_ALERT_SUCCESS"
export const FETCH_DEVICE_ALERT_FAILED = "FETCH_DEVICE_ALERT_FAILED"

export const FETCH_DEVICE_ACCURACY_ALERTS_REQUEST = "FETCH_DEVICE_ACCURACY_ALERTS_REQUEST"
export const FETCH_DEVICE_ACCURACY_ALERTS_SUCCESS = "FETCH_DEVICE_ACCURACY_ALERTS_SUCCESS"
export const FETCH_DEVICE_ACCURACY_ALERTS_FAILED = "FETCH_DEVICE_ACCURACY_ALERTS_FAILED"

export const FETCH_DEVICE_ACCURACY_ALERT_REQUEST = "FETCH_DEVICE_ACCURACY_ALERT_REQUEST"
export const FETCH_DEVICE_ACCURACY_ALERT_SUCCESS = "FETCH_DEVICE_ACCURACY_ALERT_SUCCESS"
export const FETCH_DEVICE_ACCURACY_ALERT_FAILED = "FETCH_DEVICE_ACCURACY_ALERT_FAILED"

export const FETCH_IDLE_DEVICE_ALERTS_REQUEST = "FETCH_IDLE_DEVICE_ALERTS_REQUEST"
export const FETCH_IDLE_DEVICE_ALERTS_SUCCESS = "FETCH_IDLE_DEVICE_ALERTS_SUCCESS"
export const FETCH_IDLE_DEVICE_ALERTS_FAILED = "FETCH_IDLE_DEVICE_ALERTS_FAILED"

export const FETCH_IDLE_DEVICE_ALERT_REQUEST = "FETCH_IDLE_DEVICE_ALERT_REQUEST"
export const FETCH_IDLE_DEVICE_ALERT_SUCCESS = "FETCH_IDLE_DEVICE_ALERT_SUCCESS"
export const FETCH_IDLE_DEVICE_ALERT_FAILED = "FETCH_IDLE_DEVICE_ALERT_FAILED"