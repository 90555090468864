import React, { lazy, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Auth0ProviderWithHistory from './components/providers/Auth0ProviderWithHistory'
import Layout from './components/layouts';
import AppLoader from './components/AppLoader';

const routes = [
    {
        path: '/dashboard',
        Component: lazy(() => import('./pages/index')),
        exact: true,
    },
    {
        path: '/auth/login',
        Component: lazy(() => import('./pages/auth/Login')),
        exact: true,
    },
    {
        path: '/devices',
        Component: lazy(() => import('./pages/devices')),
        exact: true,
        features: ["setupDevices"],
    },
    {
        path: '/devices/edit/:deviceId',
        Component: lazy(() => import('./pages/devices/edit')),
        exact: true,
        features: ["setupDevices"],
    },
    {
        path: '/zone-report/:zoneId',
        Component: lazy(() => import('./pages/zoneReport')),
        exact: true,
        features: ["workZones"],
    },
    {
        path: '/alerts/:alertCategory?',
        Component: lazy(() => import('./pages/alerts')),
        exact: true,
        features: ["alerts"],
    },
    {
        path: '/maps/:mapType?/:alertType?/:alertId?',
        Component: lazy(() => import('./pages/maps/index')),
        exact: true,
    },
    {
        path: '/static-zones',
        Component: lazy(() => import('./pages/staticZones')),
        exact: true,
        features: ["workZones"],
    },
    {
      path: '/static-zones/list',
      Component: lazy(() => import('./pages/staticZones/list')),
      exact: true,
      features: ["workZones"],
    },
    {
        path: '/work-sites',
        Component: lazy(() => import('./pages/poi')),
        exact: true,
        features: ["workSites"],
    },
    {
        path: '/work-sites/new',
        Component: lazy(() => import('./pages/poi/create')),
        exact: true,
        features: ["workSites"],
    },
    {
        path: '/work-sites/:poiId?/edit',
        Component: lazy(() => import('./pages/poi/edit')),
        exact: true,
        features: ["workSites"],
    },
    {
        path: '/overlays',
        Component: lazy(() => import('./pages/kmlZones')),
        exact: true,
        features: ["overlays"],
    },
    {
      path: '/overlays/list',
      Component: lazy(() => import('./pages/overlays')),
      exact: true,
      features: ["overlays"],
    },
    {
        path: '/company/settings',
        Component: lazy(() => import('./pages/company')),
        exact: true,
        features: ["settings"],
    },
    {
        path: '/company/label-editor',
        Component: lazy(() => import('./pages/company/labelEditor')),
        exact: true,
        features: ["settings"],
    },
    {
      path: '/reports/devices',
      Component: lazy(() => import('./pages/deviceReports')),
      exact: true,
      features: ["reportingDevices"],
    },
    {
      path: '/reports/devices/:deviceId',
      Component: lazy(() => import('./pages/deviceReports/device')),
      exact: true,
      features: ["reportingDevices"],
    },
    {
      path: '/site-visit-report',
      Component: lazy(() => import('./pages/siteVisitReport')),
      exact: true
    },
    {
      path: '/site-visit-report/:reportId',
      Component: lazy(() => import('./pages/siteVisitReport/EditImages')),
      exact: true
    }
]

const Router = ({
    history,
    dispatch,
    company
}: any) => {
  const featuresVisibility = company?.featuresVisibility;
  const filterRoutesOnFeatures = (route: any) => {
    if (Array.isArray(route.features)) {
      for (const feature of route.features) {
        if (featuresVisibility && featuresVisibility[feature]) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  };
  return(
    <ConnectedRouter history={history}>
      <Auth0ProviderWithHistory>
        <Layout>
          <Route
            render={
              state => {
                const { location } = state;
                return (
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {
                      routes.filter(filterRoutesOnFeatures).map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <>
                                <Suspense fallback={<AppLoader isLoading />}> <Component /> </Suspense>
                              </>
                            )
                          }}
                        />
                      ))
                    }
                  </Switch>
                )
              }
            }
          />
        </Layout>
      </Auth0ProviderWithHistory>
    </ConnectedRouter>
  )
}

const mapStateToProps = ({ companies }: any) => {
    return {
      company: companies.items
    }
}

export default connect(mapStateToProps)(Router);