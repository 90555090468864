import {
    FETCH_ZONE_REPORT_REQUEST,
    FETCH_ZONE_REPORT_SUCCESS,
    FETCH_ZONE_REPORT_FAILED,
} from './types';

const initialState = {
    loading: false,
    error: null,
    items: []
}

interface IAction {
    type: String,
    payload: any
}

const zoneReportReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_ZONE_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case FETCH_ZONE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            }

        case FETCH_ZONE_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload
            }

        default: 
            return state
    }
}

export default zoneReportReducer;