import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
    FETCH_ZONE_REPORT_REQUEST,
    FETCH_ZONE_REPORT_SUCCESS,
    FETCH_ZONE_REPORT_FAILED,
} from './types';

import {
    fetchZoneReport
} from '../../services/zoneReportService';

export function* fetchZoneReportSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchZoneReport, payload.zoneId, payload.start, payload.end)
        if(result){
            yield put({ type: FETCH_ZONE_REPORT_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_ZONE_REPORT_FAILED, payload: error })
    }
}

export default function* zoneReportSagas(){
    yield all([
        takeEvery(FETCH_ZONE_REPORT_REQUEST, fetchZoneReportSaga),
    ])
}