import ApiClient from './ApiClient';


export const fetchStaticZones = (params: any) => {
    return ApiClient.get('static-zone/', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchStaticZone = (zoneId: string) => {
    return ApiClient.get(`static-zone/${zoneId}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const createStaticZone = (params: any) => {
    return ApiClient.post(`static-zone/`, params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const deleteStaticZone = (zoneId: string) => {
    return ApiClient.delete(`static-zone/${zoneId}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updateStaticZone = (zoneId:any, params: any) => {
    return ApiClient.put(`static-zone/${zoneId}`, params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const bulkDeleteStaticZone = (formData: any) => {
  return ApiClient.post(`static-zone/bulk-delete`, formData).then( response => {
          if(response){
              return response.data
          }
          return false
      })
}