import {
  FETCH_DEVICE_REPORTS_REQUEST,
  FETCH_DEVICE_REPORTS_SUCCESS,
  FETCH_DEVICE_REPORTS_FAILED,
  GET_DEVICE_REPORT_REQUEST,
  GET_DEVICE_REPORT_SUCCESS,
  GET_DEVICE_REPORT_FAILED,
  GET_DEVICE_ZONE_REPORT_REQUEST,
  GET_DEVICE_ZONE_REPORT_SUCCESS,
  GET_DEVICE_ZONE_REPORT_FAILED
} from './types';

const initialState = {
  loading: false,
  error: null,
  items: [],
  item: null
}

interface IAction {
  type: String,
  payload: any
}

const staticZonesReducer = ( state = initialState, action: IAction ) => {
  switch (action.type) {
    case FETCH_DEVICE_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        deletedItem: null,
        item: null,
        items: []
      }

    case FETCH_DEVICE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.devices,
      }

    case FETCH_DEVICE_REPORTS_FAILED:
      return {
        ...state,
        loading: false,
        items: [],
        error: action.payload
      }
    
    case GET_DEVICE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        deletedItem: null,
        item: null,
      }

    case GET_DEVICE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload,
      }

    case GET_DEVICE_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        item: null,
        error: action.payload
      }

    case GET_DEVICE_ZONE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        item: null
      };

    case GET_DEVICE_ZONE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        zoneReport: action.payload
      };

    case GET_DEVICE_ZONE_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        item: null,
        error: action.payload
      };
  

    default:
      return state
  }
}

export default staticZonesReducer;


