import ApiClient from './ApiClient';


export const fetchStaticAlerts = (params: any) => {
    return ApiClient.get('alert/static', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchStaticAlert = (id: Number) => {
    return ApiClient.get(`alert/static/${id}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchDeviceAlerts = (params: any) => {
    return ApiClient.get('alert/device', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchIdleDeviceAlerts = (params: any) => {
  return ApiClient.get('alerts/idle-devices', { params })
      .then( response => {
          if(response){
              return response.data
          }
          return false
      })
}

export const fetchDeviceAlert = (deviceId: Number) => {
    return ApiClient.get(`alert/device/${deviceId}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchDeviceAccuracyAlerts = (params: any) => {
    return ApiClient.get('alert/device-accuracy', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchDeviceAccuracyAlert = (accuracyId: Number) => {
    return ApiClient.get(`alert/device-accuracy/${accuracyId}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchIdleDeviceAlert = (accuracyId: Number) => {
  return ApiClient.get(`alerts/idle-devices/${accuracyId}`, {})
      .then( response => {
          if(response){
              return response.data
          }
          return false
      })
}