import { all } from 'redux-saga/effects';

import deviceSagas from './devices/sagas';
import alertSagas from './alerts/sagas';
import companiesSagas from './companies/sagas';
import staticZoneSagas from './staticZones/sagas';
import zoneReportSagas from './zoneReport/sagas';
import poiSagas from './poi/sagas';
import kmlSagas from './kml/sagas';
import statSagas from './stats/sagas';
import reportingSagas from './reporting/sagas';

export default function* sagas() {
  yield all([
    deviceSagas(),
    alertSagas(),
    companiesSagas(),
    staticZoneSagas(),
    zoneReportSagas(),
    poiSagas(),
    kmlSagas(),
    statSagas(),
    reportingSagas()
  ])
}
