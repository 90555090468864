import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
    fetchStaticZones,
    fetchStaticZone,
    createStaticZone,
    deleteStaticZone,
    updateStaticZone,
    bulkDeleteStaticZone
} from '../../services/staticZoneService'
import {
    FETCH_STATIC_ZONES_REQUEST,
    FETCH_STATIC_ZONES_SUCCESS,
    FETCH_STATIC_ZONES_FAILED,
    FETCH_STATIC_ZONE_REQUEST,
    FETCH_STATIC_ZONE_SUCCESS,
    FETCH_STATIC_ZONE_FAILED,
    CREATE_STATIC_ZONE_REQUEST,
    CREATE_STATIC_ZONE_SUCCESS,
    CREATE_STATIC_ZONE_FAILED,
    DELETE_STATIC_ZONE_REQUEST,
    DELETE_STATIC_ZONE_SUCCESS,
    DELETE_STATIC_ZONE_FAILED,
    UPDATE_STATIC_ZONE_REQUEST,
    UPDATE_STATIC_ZONE_SUCCESS,
    UPDATE_STATIC_ZONE_FAILED,
    BULK_DELETE_STATIC_ZONE_REQUEST,
    BULK_DELETE_STATIC_ZONE_SUCCESS,
    BULK_DELETE_STATIC_ZONE_FAILED
} from './types';

export function* fetchStaticZonesSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchStaticZones, payload)
        if(result){
            yield put({ type: FETCH_STATIC_ZONES_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_STATIC_ZONES_FAILED, payload: error })
    }
}

export function* fetchStaticZoneSaga({payload}: any): Generator<any> {
    try {
        const { zoneId } = payload;
        const result = yield call(fetchStaticZone, zoneId)
        if(result){
            yield put({ type: FETCH_STATIC_ZONE_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_STATIC_ZONE_FAILED, payload: error })
    }
}

export function* createStaticZoneSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(createStaticZone, payload)
        if(result){
            yield put({ type: CREATE_STATIC_ZONE_SUCCESS, payload: result })
            yield put({ type: FETCH_STATIC_ZONES_REQUEST, payload: {} })
        }
    } catch (error) {
        yield put({ type: CREATE_STATIC_ZONE_FAILED, payload: error })
    }
}

export function* deleteStaticZoneSaga({payload}: any):Generator<any> {
    try {
        const { id } = payload;
        const result = yield call(deleteStaticZone, id)
        yield put({ type: DELETE_STATIC_ZONE_SUCCESS, payload: result })
        yield put({ type: FETCH_STATIC_ZONES_REQUEST, payload: {} })
    } catch (error) {
        yield put({ type: DELETE_STATIC_ZONE_FAILED, payload: error })
    }
}

export function* updateStaticZoneSaga({payload}: any):Generator<any> {
    try {
        const { id, params } = payload;
        const result = yield call(updateStaticZone, id, params)
        yield put({ type: UPDATE_STATIC_ZONE_SUCCESS, payload: result })
        //yield put({ type: FETCH_STATIC_ZONES_REQUEST, payload: {} })
    } catch (error) {
        yield put({ type: UPDATE_STATIC_ZONE_FAILED, payload: error })
    }
}

export function* bulkDeleteStaticZoneSaga({payload}: any): Generator<any> {
  try {
      const result = yield call(bulkDeleteStaticZone, payload)
      if(result){
          yield put({ type: BULK_DELETE_STATIC_ZONE_SUCCESS, payload: result })
          yield put({ type: FETCH_STATIC_ZONES_REQUEST, payload: {} })
      }
  } catch (error) {
      yield put({ type: BULK_DELETE_STATIC_ZONE_FAILED, payload: error })
  }
}

export default function* staticZoneSagas(){
    yield all([
        takeEvery(FETCH_STATIC_ZONES_REQUEST, fetchStaticZonesSaga),
        takeEvery(FETCH_STATIC_ZONE_REQUEST, fetchStaticZoneSaga),
        takeEvery(CREATE_STATIC_ZONE_REQUEST, createStaticZoneSaga),
        takeEvery(DELETE_STATIC_ZONE_REQUEST, deleteStaticZoneSaga),
        takeEvery(UPDATE_STATIC_ZONE_REQUEST, updateStaticZoneSaga),
        takeEvery(BULK_DELETE_STATIC_ZONE_REQUEST, bulkDeleteStaticZoneSaga)
    ])
}