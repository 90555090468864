export const FETCH_POI_LIST_REQUEST = "FETCH_POI_LIST_REQUEST"
export const FETCH_POI_LIST_SUCCESS = "FETCH_POI_LIST_SUCCESS"
export const FETCH_POI_LIST_FAILED = "FETCH_POI_LIST_FAILED"

export const FETCH_POI_REQUEST = "FETCH_POI_REQUEST"
export const FETCH_POI_SUCCESS = "FETCH_POI_SUCCESS"
export const FETCH_POI_FAILED = "FETCH_POI_FAILED"

export const CREATE_POI_REQUEST = "CREATE_POI_REQUEST"
export const CREATE_POI_SUCCESS = "CREATE_POI_SUCCESS"
export const CREATE_POI_FAILED = "CREATE_POI_FAILED"

export const UPDATE_POI_REQUEST = "UPDATE_POI_REQUEST"
export const UPDATE_POI_SUCCESS = "UPDATE_POI_SUCCESS"
export const UPDATE_POI_FAILED = "UPDATE_POI_FAILED"

export const DELETE_POI_REQUEST = "DELETE_POI_REQUEST"
export const DELETE_POI_SUCCESS = "DELETE_POI_SUCCESS"
export const DELETE_POI_FAILED = "DELETE_POI_FAILED"