export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST"
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS"
export const FETCH_COMPANIES_FAILED = "FETCH_COMPANIES_FAILED"

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED"

export const UPDATE_COMPANY_NOTIFICATIONS_REQUEST = "UPDATE_COMPANY_NOTIFICATIONS_REQUEST"
export const UPDATE_COMPANY_NOTIFICATIONS_SUCCESS = "UPDATE_COMPANY_NOTIFICATIONS_SUCCESS"
export const UPDATE_COMPANY_NOTIFICATIONS_FAILED = "UPDATE_COMPANY_NOTIFICATIONS_FAILED"

export const GET_COMPANY_NOTIFICATIONS_REQUEST = "GET_COMPANY_NOTIFICATIONS_REQUEST"
export const GET_COMPANY_NOTIFICATIONS_SUCCESS = "GET_COMPANY_NOTIFICATIONS_SUCCESS"
export const GET_COMPANY_NOTIFICATIONS_FAILED = "GET_COMPANY_NOTIFICATIONS_FAILED"

