import ApiClient from './ApiClient';

export const fetchDashboardStats = (params: any) => {
  return ApiClient.get('stats/dashboard', { params })
      .then( response => {
          if(response){
              return response.data
          }
          return false
      })
}