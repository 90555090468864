import { all, takeEvery, put, call } from 'redux-saga/effects';

import { fetchDashboardStats } from '../../services/statService';
import {
  FETCH_DASHBOARD_STATS_REQUEST,
  FETCH_DASHBOARD_STATS_SUCCESS,
  FETCH_DASHBOARD_STATS_FAILED,
} from './types';

export function* fetchDashboardStatsSaga({payload}: any): Generator<any> {
  try {
      const result = yield call(fetchDashboardStats, payload)
      if(result){
          yield put({ type: FETCH_DASHBOARD_STATS_SUCCESS, payload: result })
      }
  } catch (error) {
      yield put({ type: FETCH_DASHBOARD_STATS_FAILED, payload: error })
  }
}

export default function* staticZoneSagas(){
  yield all([
      takeEvery(FETCH_DASHBOARD_STATS_REQUEST, fetchDashboardStatsSaga),
  ])
}