import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
    FETCH_COMPANIES_REQUEST,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILED,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILED,
    GET_COMPANY_NOTIFICATIONS_REQUEST,
    GET_COMPANY_NOTIFICATIONS_SUCCESS,
    GET_COMPANY_NOTIFICATIONS_FAILED
} from './types';

import {
    fetchCompanies,
    updateCompany,
    getCompanySubs
} from '../../services/companyService';

export function* fetchCompaniesSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchCompanies, payload)
        if(result){
            yield put({ type: FETCH_COMPANIES_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_COMPANIES_FAILED, payload: error })
    }
}

export function* updateDeviceSaga({payload}: any):Generator<any> {
    try {
        const { id, data } = payload;
        const result = yield call(updateCompany, id, data)
        if(result){
            yield put({ type: UPDATE_COMPANY_SUCCESS, payload: result })
            yield put({ type: FETCH_COMPANIES_REQUEST})
            yield put({ type: GET_COMPANY_NOTIFICATIONS_REQUEST, payload: id})
        }
    } catch (error) {
        yield put({ type: UPDATE_COMPANY_FAILED, payload: error })
    }
}

export function* fetchCompanySubs({payload}: any): Generator<any> {
    try {
        const result = yield call(getCompanySubs, payload)
        if(result){
            yield put({ type: GET_COMPANY_NOTIFICATIONS_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: GET_COMPANY_NOTIFICATIONS_FAILED, payload: error })
    }
}

export default function* companiesSagas(){
    yield all([
        takeEvery(FETCH_COMPANIES_REQUEST, fetchCompaniesSaga),
        takeEvery(UPDATE_COMPANY_REQUEST, updateDeviceSaga),
        takeEvery(GET_COMPANY_NOTIFICATIONS_REQUEST, fetchCompanySubs)
    ])
}