import React from 'react';
import Loader from 'react-loader-spinner';


type Props = {
    isLoading: boolean
}
  
const AppLoader = ({
    isLoading
}: Props) => {
    if(!isLoading){
        return (null)
    }
    return (
        <div className="site-loader">
            <Loader type="BallTriangle" height={80} width={80} />
        </div>
    )
}

export default AppLoader;