import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import initStore from './redux/store';
import Router from './router';

import './styles/app.css'

const history = createBrowserHistory({
  basename: ''
})
//process.env.PUBLIC_URL
const store = initStore(history);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

export { store, history };