import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';


import devicesReducer from './devices/reducer';
import alertsReducer from './alerts/reducer';
import companiesReducer from './companies/reducer';
import staticZoneReducer from './staticZones/reducers';
import zoneReportReducer from './zoneReport/reducer';
import poiReducer from './poi/reducers';
import kmlReducer from './kml/reducers';
import statReducer from './stats/reducers';
import reportingReducer from './reporting/reducers';

const reducer = (history: any) => {
    return combineReducers({
        router: connectRouter(history),
        devices: devicesReducer,
        alerts: alertsReducer,
        companies: companiesReducer,
        staticZones: staticZoneReducer,
        zoneReport: zoneReportReducer,
        poi: poiReducer,
        kmls: kmlReducer,
        stats: statReducer,
        reporting: reportingReducer
    })
}

export default reducer;