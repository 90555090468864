export const defaultAppLabels:any = {
  menus: {
    dashboard: "Dashboard",
    setup: "Setup",
    devices: "Devices",
    work_sites: "Work Sites",
    work_zones: "Work Zones",
    overlays: "Overlays",
    settings: "Settings",
    reporting: "Reporting",
    reportings_realtime_map: "Realtime Map",
    reportings_replay: "Replay",
    reportings_device_path: "Device Path",
    reportings_alerts: "Alerts",
    reportings_devices: "Devices",
    reportings_site_visit_report: "Site Visit Report"
  },
  dashboard_page: {
    card_devices: "Devices",
    card_top_device: "Top Device",
    card_top_zone: "Top Zone",
    table_latest_zone_alerts: "Latest Zone Alerts",
    table_latest_device_alerts: "Latest Device Alerts",
    table_device_accurancy_alerts: "Latest Device Accuracy Alerts",
    table_idle_device_alerts: "Latest Idle Device Alerts",
    tbl_col_device: "Device",
    tbl_col_device1: "Device 1",
    tbl_col_device2: "Device 2",
    tbl_col_common_name: "Common Name",
    tbl_col_zone: "Zone",
    tbl_col_start: "Start",
    tbl_col_end: "End",
    tbl_col_replay: "Replay",
    card_mobile_devices: "Mobile Devices",
    card_3rd_party_devices: "3rd Party Devices",
  },
  devices_page: {
    table_base_stations: "Base Stations",
    table_devices: "Devices",
    tbl_col_device_id: "Device ID",
    tbl_col_common_name: "Common Name",
    tbl_col_status: "Status",
    tbl_col_redis: "Radius",
    tbl_mobile_devices: "Mobile Devices",
    tbl_third_party_devices: "3rd Party Devices",
  },
  rpt_alerts_page: {
    table_device_alerts: "Device Alerts",
		table_device_accurancy_alerts: "Device Accuracy Alerts",
		table_device_zone_alerts: "Zone Alerts",
    table_idle_device_alerts: "Idle Device Alerts",
  },
  rpt_devices_page: {
    table_device_reports: "Device Reports",
    tbl_col_device_id: "Device ID",
    tbl_col_common_name: "Common Name",
    tbl_col_total_time: "Total Time"
  }
};

export const pageSections:any = {
  menus: "Sidebar Navigation Menus",
  dashboard_page: "Dashboard Page",
  devices_page: "Devices Page",
  rpt_alerts_page: "Reporting - Alerts Page",
  rpt_devices_page: "Reporting - Devices Page",
}