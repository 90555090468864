import {
    FETCH_COMPANIES_REQUEST,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILED,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILED,
    GET_COMPANY_NOTIFICATIONS_REQUEST,
    GET_COMPANY_NOTIFICATIONS_SUCCESS,
    GET_COMPANY_NOTIFICATIONS_FAILED
} from './types';
import { defaultAppLabels } from '../../utils/label.config';

const initialState = {
    loading: false,
    error: null,
    items: [],
    appLabels: defaultAppLabels,
    updatedItem: null,
    snsSubscriptions: null,
    dashboardPageConfig: null
}

interface IAction {
    type: String,
    payload: any
}

const companiesReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_COMPANIES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updatedItem: null
            }

        case FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                appLabels: {...state.appLabels, ...action.payload.appLabels},
                dashboardPageConfig: {...action.payload.dashboardPageConfig}
            }

        case FETCH_COMPANIES_FAILED:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload
            }
        
        case UPDATE_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updatedItem: null
            }

        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedItem: action.payload,
            }

        case UPDATE_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                updatedItem: null,
                error: action.payload
            }
        
        case GET_COMPANY_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                snsSubscriptions: null
            }

        case GET_COMPANY_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                snsSubscriptions: action.payload,
            }

        case GET_COMPANY_NOTIFICATIONS_FAILED:
            return {
                ...state,
                loading: false,
                snsSubscriptions: null,
                error: action.payload
            }

        default: 
            return state
    }
}

export default companiesReducer;