import {
    FETCH_COMPANIES_REQUEST,
    UPDATE_COMPANY_REQUEST,
    GET_COMPANY_NOTIFICATIONS_REQUEST
} from './types';

export const fetchCompanies = (payload: any) => {
    return {
        type: FETCH_COMPANIES_REQUEST,
        payload
    }
}

export const updateCompany = (id: any, data: any) => {
    return {
        type: UPDATE_COMPANY_REQUEST,
        payload: { id, data }
    }
}

export const getCompanySubs = (id:any) => {
    return {
        type: GET_COMPANY_NOTIFICATIONS_REQUEST,
        payload: id
    }
}