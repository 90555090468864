import * as Effects from "redux-saga/effects";

import { history } from '../../index';
import {
    fetchDevices,
    fetchDevice,
    fetchKml,
    fetchReplays,
    fetchReplayDates,
    fetchReplayStaticDates,
    fetchStaticReplay,
    updateDevice
} from '../../services/deviceService';
import {
    FETCH_DEVICES_REQUEST,
    FETCH_DEVICES_SUCCESS,
    FETCH_DEVICES_FAILED,
    FETCH_DEVICE_REQUEST,
    FETCH_DEVICE_SUCCESS,
    FETCH_DEVICE_FAILED,
    FETCH_KML_REQUEST,
    FETCH_KML_SUCCESS,
    FETCH_KML_FAILED,
    FETCH_REPLAYS_REQUEST,
    FETCH_REPLAYS_SUCCESS,
    FETCH_REPLAYS_FAILED,
    FETCH_REPLAY_DATES_REQUEST,
    FETCH_REPLAY_DATES_SUCCESS,
    FETCH_REPLAY_DATES_FAILED,
    FETCH_REPLAY_STATIC_REQUEST,
    FETCH_REPLAY_STATIC_SUCCESS,
    FETCH_REPLAY_STATIC_FAILED,
    FETCH_REPLAY_STATIC_DATES_REQUEST,
    FETCH_REPLAY_STATIC_DATES_SUCCESS,
    FETCH_REPLAY_STATIC_DATES_FAILED,
    UPDATE_DEVICE_REQUEST,
    UPDATE_DEVICE_SUCCESS,
    UPDATE_DEVICE_FAILED
} from './types'

const all: any = Effects.all;
const takeEvery: any = Effects.takeEvery
const put: any = Effects.put
const call: any = Effects.call

export function* fetchDevicesSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchDevices, payload)
        if(result){
            yield put({ type: FETCH_DEVICES_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_DEVICES_FAILED, payload: error })
    }
}

export function* fetchDeviceSaga({payload}: any):Generator<any> {
    try {
        const { id } = payload;
        const result = yield call(fetchDevice, id)
        if(result){
            yield put({ type: FETCH_DEVICE_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_DEVICE_FAILED, payload: error })
    }
}

export function* updateDeviceSaga({payload}: any):Generator<any> {
    try {
        const { id, data } = payload;
        const result = yield call(updateDevice, id, data)
        if(result){
            yield put({ type: UPDATE_DEVICE_SUCCESS, payload: result })
            yield history.push('/devices')
        }
    } catch (error) {
        yield put({ type: UPDATE_DEVICE_FAILED, payload: error })
    }
}

export function* fetchKMLSaga({payload}: any):Generator<any> {
    try {
        const result = yield call(fetchKml, payload)
        if(result){
            yield put({ type: FETCH_KML_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_KML_FAILED, payload: error })
    }
}

export function* fetchReplaysSaga({payload}: any):Generator<any> {
    try {
        const result = yield call(fetchReplays, payload.start, payload.minutes)
        if(result){
            yield put({ type: FETCH_REPLAYS_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_REPLAYS_FAILED, payload: error })
    }
}

export function* fetchReplayDatesSaga({payload}: any):Generator<any> {
  try {
      const result = yield call(fetchReplayDates, payload.date)
      if(result){
          yield put({ type: FETCH_REPLAY_DATES_SUCCESS, payload: result })
      }
  } catch (error) {
      yield put({ type: FETCH_REPLAY_DATES_FAILED, payload: error })
  }
}

export function* fetchReplayStaticSaga({payload}: any):Generator<any> {
    try {
        const result = yield call(fetchStaticReplay, payload.start, payload.end, payload.deviceId)
        if(result){
            yield put({ type: FETCH_REPLAY_STATIC_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_REPLAY_STATIC_FAILED, payload: error })
    }
}

export function* fetchReplayStaticDatesSaga({payload}: any):Generator<any> {
  try {
      const result = yield call(fetchReplayStaticDates, payload.date, payload.deviceId)
      if(result){
          yield put({ type: FETCH_REPLAY_STATIC_DATES_SUCCESS, payload: result })
      }
  } catch (error) {
      yield put({ type: FETCH_REPLAY_STATIC_DATES_FAILED, payload: error })
  }
}



export default function* deviceSagas(){
    yield all([
        takeEvery(FETCH_DEVICES_REQUEST, fetchDevicesSaga),
        takeEvery(FETCH_DEVICE_REQUEST, fetchDeviceSaga),
        takeEvery(FETCH_KML_REQUEST, fetchKMLSaga),
        takeEvery(FETCH_REPLAYS_REQUEST, fetchReplaysSaga),
        takeEvery(FETCH_REPLAY_DATES_REQUEST, fetchReplayDatesSaga),
        takeEvery(FETCH_REPLAY_STATIC_REQUEST, fetchReplayStaticSaga),
        takeEvery(FETCH_REPLAY_STATIC_DATES_REQUEST, fetchReplayStaticDatesSaga),
        takeEvery(UPDATE_DEVICE_REQUEST, updateDeviceSaga)
    ])
}