import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
  fetchAllDeviceReport,
  fetchDeviceReport,
  fetchDeviceZoneReport
} from '../../services/reportService';
import {
  FETCH_DEVICE_REPORTS_REQUEST,
  FETCH_DEVICE_REPORTS_SUCCESS,
  FETCH_DEVICE_REPORTS_FAILED,
  GET_DEVICE_REPORT_REQUEST,
  GET_DEVICE_REPORT_SUCCESS,
  GET_DEVICE_REPORT_FAILED,
  GET_DEVICE_ZONE_REPORT_REQUEST,
  GET_DEVICE_ZONE_REPORT_SUCCESS,
  GET_DEVICE_ZONE_REPORT_FAILED
} from './types';

export function* fetchAllDeviceReportSaga({payload}: any): Generator<any> {
  try {
    const { params } = payload;
    const result = yield call(fetchAllDeviceReport, params)
    if(result){
      yield put({ type: FETCH_DEVICE_REPORTS_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: FETCH_DEVICE_REPORTS_FAILED, payload: error })
  }
}

export function* fetchDeviceReportSaga({payload}: any): Generator<any> {
  try {
    const { id, params } = payload;
    const result = yield call(fetchDeviceReport, id, params)
    if(result){
      yield put({ type: GET_DEVICE_REPORT_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: GET_DEVICE_REPORT_FAILED, payload: error })
  }
}

export function* fetchDeviceZoneReportSaga({payload}: any): Generator<any> {
  try {
    const { id, params } = payload;
    const result = yield call(fetchDeviceZoneReport, id, params)
    if(result){
      yield put({ type: GET_DEVICE_ZONE_REPORT_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: GET_DEVICE_ZONE_REPORT_FAILED, payload: error })
  }
}

export default function* reportingSagas(){
  yield all([
      takeEvery(GET_DEVICE_ZONE_REPORT_REQUEST, fetchDeviceZoneReportSaga),
      takeEvery(FETCH_DEVICE_REPORTS_REQUEST, fetchAllDeviceReportSaga),
      takeEvery(GET_DEVICE_REPORT_REQUEST, fetchDeviceReportSaga),
  ])
}