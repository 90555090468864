import {
    FETCH_KML_LIST_REQUEST,
    FETCH_KML_LIST_SUCCESS,
    FETCH_KML_LIST_FAILED,
    FETCH_KML_REQUEST,
    FETCH_KML_SUCCESS,
    FETCH_KML_FAILED,
    CREATE_KML_REQUEST,
    CREATE_KML_SUCCESS,
    CREATE_KML_FAILED,
    UPDATE_KML_REQUEST,
    UPDATE_KML_SUCCESS,
    UPDATE_KML_FAILED,
    DELETE_KML_REQUEST,
    DELETE_KML_SUCCESS,
    DELETE_KML_FAILED,
    UPLOAD_KML_FILE_REQUEST,
    UPLOAD_KML_FILE_SUCCESS,
    UPLOAD_KML_FILE_FAILED,
    BULK_DELETE_KML_REQUEST,
    BULK_DELETE_KML_SUCCESS,
    BULK_DELETE_KML_FAILED
} from './types';

const initialState = {
    loading: false,
    error: null,
    items: [],
    item: null,
    deletedItem: null,
    bulkDelete: null
}

interface IAction {
    type: String,
    payload: any
}

const kmlReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_KML_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                //items: [],
                newItem: null,
                updatedItem: null,
                bulkDelete: null
            }
        
        case FETCH_KML_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            }
        
        case FETCH_KML_LIST_FAILED:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload
            }
        
        case FETCH_KML_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                item: null,
            }
        
        case FETCH_KML_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
            }
        
        case FETCH_KML_FAILED:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload
            }
        
        case CREATE_KML_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                newItem: null,
            }
        
        case CREATE_KML_SUCCESS:
            return {
                ...state,
                loading: false,
                newItem: action.payload,
            }
        
        case CREATE_KML_FAILED:
            return {
                ...state,
                loading: false,
                newItem: null,
                error: action.payload
            }
        
        case UPDATE_KML_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updatedItem: null,
            }
        
        case UPDATE_KML_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedItem: action.payload,
            }
        
        case UPDATE_KML_FAILED:
            return {
                ...state,
                loading: false,
                updatedItem: null,
                error: action.payload
            }
        
        case DELETE_KML_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deletedItem: null,
            }
        
        case DELETE_KML_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedItem: action.payload,
            }
        
        case DELETE_KML_FAILED:
            return {
                ...state,
                loading: false,
                deletedItem: null,
                error: action.payload
            }
        
        case UPLOAD_KML_FILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                uploaded: null,
            }
        
        case UPLOAD_KML_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                uploaded: action.payload,
            }
        
        case UPLOAD_KML_FILE_FAILED:
            return {
                ...state,
                loading: false,
                uploaded: null,
                error: action.payload
            }
        
        case BULK_DELETE_KML_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                bulkDelete: null,
            }
        
        case BULK_DELETE_KML_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkDelete: action.payload,
            }
        
        case BULK_DELETE_KML_FAILED:
            return {
                ...state,
                loading: false,
                bulkDelete: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default kmlReducer;