import {
    FETCH_STATIC_ALERTS_REQUEST,
    FETCH_STATIC_ALERTS_SUCCESS,
    FETCH_STATIC_ALERTS_FAILED,
    
    FETCH_STATIC_ALERT_REQUEST,
    FETCH_STATIC_ALERT_SUCCESS,
    FETCH_STATIC_ALERT_FAILED,
    
    FETCH_DEVICE_ALERTS_REQUEST,
    FETCH_DEVICE_ALERTS_SUCCESS,
    FETCH_DEVICE_ALERTS_FAILED,

    FETCH_DEVICE_ALERT_REQUEST,
    FETCH_DEVICE_ALERT_SUCCESS,
    FETCH_DEVICE_ALERT_FAILED,

    FETCH_DEVICE_ACCURACY_ALERTS_REQUEST,
    FETCH_DEVICE_ACCURACY_ALERTS_SUCCESS,
    FETCH_DEVICE_ACCURACY_ALERTS_FAILED,

    FETCH_DEVICE_ACCURACY_ALERT_REQUEST,
    FETCH_DEVICE_ACCURACY_ALERT_SUCCESS,
    FETCH_DEVICE_ACCURACY_ALERT_FAILED,

    FETCH_IDLE_DEVICE_ALERTS_REQUEST,
    FETCH_IDLE_DEVICE_ALERTS_SUCCESS,
    FETCH_IDLE_DEVICE_ALERTS_FAILED,

    FETCH_IDLE_DEVICE_ALERT_REQUEST,
    FETCH_IDLE_DEVICE_ALERT_SUCCESS,
    FETCH_IDLE_DEVICE_ALERT_FAILED
} from './types';

const initialState = {
    loading: false,
    error: null,
    staticAlerts: [],
    staticAlert: null,
    deviceAlerts: [],
    deviceAlert: null,
    deviceAccuracyAlerts: [],
    deviceAccuracyAlert: null,
}

interface IAction {
    type: String,
    payload: any
}

const alertReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_STATIC_ALERTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                staticAlerts: []
            }

        case FETCH_STATIC_ALERTS_SUCCESS:
            return {
                ...state,
                loading: false,
                staticAlerts: action.payload,
            }

        case FETCH_STATIC_ALERTS_FAILED:
            return {
                ...state,
                loading: false,
                staticAlerts: [],
                error: action.payload
            }
        
        case FETCH_STATIC_ALERT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                staticAlert: null
            }

        case FETCH_STATIC_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                staticAlert: action.payload,
            }

        case FETCH_STATIC_ALERT_FAILED:
            return {
                ...state,
                loading: false,
                staticAlert: null,
                error: action.payload
            }
        
        case FETCH_DEVICE_ALERTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deviceAlerts: []
            }

        case FETCH_DEVICE_ALERTS_SUCCESS:
            return {
                ...state,
                loading: false,
                deviceAlerts: action.payload,
            }

        case FETCH_DEVICE_ALERTS_FAILED:
            return {
                ...state,
                loading: false,
                deviceAlerts: [],
                error: action.payload
            }
        
        case FETCH_DEVICE_ALERT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deviceAlert: null
            }

        case FETCH_DEVICE_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                deviceAlert: action.payload,
            }

        case FETCH_DEVICE_ALERT_FAILED:
            return {
                ...state,
                loading: false,
                deviceAlert: null,
                error: action.payload
            }
        
        case FETCH_DEVICE_ACCURACY_ALERTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deviceAccuracyAlerts: []
            }

        case FETCH_DEVICE_ACCURACY_ALERTS_SUCCESS:
            return {
                ...state,
                loading: false,
                deviceAccuracyAlerts: action.payload,
            }

        case FETCH_DEVICE_ACCURACY_ALERTS_FAILED:
            return {
                ...state,
                loading: false,
                deviceAccuracyAlerts: [],
                error: action.payload
            }
        
        case FETCH_DEVICE_ACCURACY_ALERT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deviceAccuracyAlert: null,
            }

        case FETCH_DEVICE_ACCURACY_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                deviceAccuracyAlert: action.payload,
            }

        case FETCH_DEVICE_ACCURACY_ALERT_FAILED:
            return {
                ...state,
                loading: false,
                deviceAccuracyAlert: null,
                error: action.payload
            }
        
        case FETCH_IDLE_DEVICE_ALERTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                idleDeviceAlerts: []
            }

        case FETCH_IDLE_DEVICE_ALERTS_SUCCESS:
            return {
                ...state,
                loading: false,
                idleDeviceAlerts: action.payload,
            }

        case FETCH_IDLE_DEVICE_ALERTS_FAILED:
            return {
                ...state,
                loading: false,
                idleDeviceAlerts: [],
                error: action.payload
            }
        
        case FETCH_IDLE_DEVICE_ALERT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                idleDeviceAlert: null
            }

        case FETCH_IDLE_DEVICE_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                idleDeviceAlert: action.payload,
            }

        case FETCH_IDLE_DEVICE_ALERT_FAILED:
            return {
                ...state,
                loading: false,
                idleDeviceAlert: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default alertReducer;