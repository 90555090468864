import ApiClient from './ApiClient';


export const fetchCompanies = (params: any) => {
    return ApiClient.get('company/', { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updateCompany = (companyId: any, payload: any) => {
    return ApiClient.put(`company/${companyId}`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getCompanySubs = (companyId: any) => {
    return ApiClient.get(`company/${companyId}/notifications`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}