import ApiClient from './ApiClient';

export const fetchZoneReport = (zoneId: string, start: string, end: string) => {
    return ApiClient.get(`static-zone/${zoneId}/summary/${start}/${end}`, {})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}