export const FETCH_KML_LIST_REQUEST = "FETCH_KML_LIST_REQUEST"
export const FETCH_KML_LIST_SUCCESS = "FETCH_KML_LIST_SUCCESS"
export const FETCH_KML_LIST_FAILED = "FETCH_KML_LIST_FAILED"

export const FETCH_KML_REQUEST = "FETCH_KML_REQUEST"
export const FETCH_KML_SUCCESS = "FETCH_KML_SUCCESS"
export const FETCH_KML_FAILED = "FETCH_KML_FAILED"

export const CREATE_KML_REQUEST = "CREATE_KML_REQUEST"
export const CREATE_KML_SUCCESS = "CREATE_KML_SUCCESS"
export const CREATE_KML_FAILED = "CREATE_KML_FAILED"

export const UPDATE_KML_REQUEST = "UPDATE_KML_REQUEST"
export const UPDATE_KML_SUCCESS = "UPDATE_KML_SUCCESS"
export const UPDATE_KML_FAILED = "UPDATE_KML_FAILED"

export const DELETE_KML_REQUEST = "DELETE_KML_REQUEST"
export const DELETE_KML_SUCCESS = "DELETE_KML_SUCCESS"
export const DELETE_KML_FAILED = "DELETE_KML_FAILED"

export const UPLOAD_KML_FILE_REQUEST = "UPLOAD_KML_FILE_REQUEST"
export const UPLOAD_KML_FILE_SUCCESS = "UPLOAD_KML_FILE_SUCCESS"
export const UPLOAD_KML_FILE_FAILED = "UPLOAD_KML_FILE_FAILED"

export const BULK_DELETE_KML_REQUEST = "BULK_DELETE_KML_REQUEST"
export const BULK_DELETE_KML_SUCCESS = "BULK_DELETE_KML_SUCCESS"
export const BULK_DELETE_KML_FAILED = "BULK_DELETE_KML_FAILED"