import React from 'react'
import { Anchor, AnchorProps } from 'grommet/components/Anchor';
import { Link, LinkProps } from 'react-router-dom';

//Enhance Grommet Anchor with ReactRouter Link
export const AnchorLink: React.FC<AnchorLinkProps> = props => {
    return (
        <Anchor 
            as={({ colorProp, hasIcon, hasLabel, focus, ...p }) => <Link {...p} />} 
            {...props} 
        />
    )
}
  
export type AnchorLinkProps = LinkProps &
    AnchorProps &
    Omit<JSX.IntrinsicElements['a'], 'color'>