import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducer from './reducer';
import sagas from './sagas';


const initStore = (history: any) => {
    const sagaMiddleware = createSagaMiddleware()
    const routeMiddleware = routerMiddleware(history)
    const middlewares = [sagaMiddleware, routeMiddleware]

    if (process.env.NODE_ENV === 'development') {
        //middlewares.push(logger)
    }

    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        reducer(history),
        composeEnhancers(applyMiddleware(...middlewares)),
    )

    sagaMiddleware.run(sagas)
    
    return store;
}

export default initStore