import axios from 'axios'
import store from 'store'

// import { notification } from 'antd'

const ApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
})

ApiClient.interceptors.request.use( request => {
  const accessToken = store.get('accessToken')
  const companyId = store.get('companyId');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken} ${companyId}`;
  }
  return request
})

/*
ApiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data && data.error) {

  }
})
*/

export default ApiClient
