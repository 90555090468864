import {
    FETCH_STATIC_ZONES_REQUEST,
    FETCH_STATIC_ZONES_SUCCESS,
    FETCH_STATIC_ZONES_FAILED,
    FETCH_STATIC_ZONE_REQUEST,
    FETCH_STATIC_ZONE_SUCCESS,
    FETCH_STATIC_ZONE_FAILED,
    CREATE_STATIC_ZONE_REQUEST,
    CREATE_STATIC_ZONE_SUCCESS,
    CREATE_STATIC_ZONE_FAILED,
    DELETE_STATIC_ZONE_REQUEST,
    DELETE_STATIC_ZONE_SUCCESS,
    DELETE_STATIC_ZONE_FAILED,
    UPDATE_STATIC_ZONE_REQUEST,
    UPDATE_STATIC_ZONE_SUCCESS,
    UPDATE_STATIC_ZONE_FAILED,
    BULK_DELETE_STATIC_ZONE_REQUEST,
    BULK_DELETE_STATIC_ZONE_SUCCESS,
    BULK_DELETE_STATIC_ZONE_FAILED
} from './types';

const initialState = {
    loading: false,
    error: null,
    items: [],
    item: null,
    deletedItem: null,
    updatedItem: null,
    bulkDelete: null
}

interface IAction {
    type: String,
    payload: any
}

const staticZonesReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_STATIC_ZONES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deletedItem: null,
                item: null,
                items: []
            }

        case FETCH_STATIC_ZONES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            }

        case FETCH_STATIC_ZONES_FAILED:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload
            }
        
        case FETCH_STATIC_ZONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_STATIC_ZONE_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
            }

        case FETCH_STATIC_ZONE_FAILED:
            return {
                ...state,
                loading: false,
                item:  null,
                error: action.payload
            }
        
        case CREATE_STATIC_ZONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case CREATE_STATIC_ZONE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case CREATE_STATIC_ZONE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case DELETE_STATIC_ZONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case DELETE_STATIC_ZONE_SUCCESS:
            const filteredItems = state.items.filter((item:any) => item._id !== action.payload);
            return {...state, loading: false, error: null ,items: filteredItems}

        case DELETE_STATIC_ZONE_FAILED:
            return {
                ...state,
                loading: false,
                items: state.items,
                deletedItem: null,
                error: action.payload
            }
        
        case UPDATE_STATIC_ZONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updatedItem: null,
            }

        case UPDATE_STATIC_ZONE_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedItem: action.payload,
            }

        case UPDATE_STATIC_ZONE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
                updatedItem: null,
            }
        case BULK_DELETE_STATIC_ZONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                bulkDelete: null,
            }
        
        case BULK_DELETE_STATIC_ZONE_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkDelete: action.payload,
            }
        
        case BULK_DELETE_STATIC_ZONE_FAILED:
            return {
                ...state,
                loading: false,
                bulkDelete: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default staticZonesReducer;