import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { Grommet, Grid, Main, Sidebar, Nav, Menu } from 'grommet';
import { Dashboard, Device, Navigate, Select, Map, Alert, Notification, SettingsOption, MapLocation, Organization } from 'grommet-icons';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { AnchorLink } from '../../AnchorLink';
import AppLoader from '../../AppLoader';
import { fetchCompanies } from '../../../redux/companies/actions';
import { useCustomLabel } from '../../../utils/hooks';

const mapStateToProps = ({ companies }: any) => {
    return {
        company: companies.items
    }
}

const MainLayout = ({ children, company, dispatch } : any) => {
    const { logout, isAuthenticated } = useAuth0();
    const [lbl] = useCustomLabel();
    useEffect(() => {
        if (isAuthenticated) {
            const getCompanies = setTimeout(() => {
                dispatch(fetchCompanies({}))
            }, 0);
            return () => clearTimeout(getCompanies);
        }
    }, [dispatch, isAuthenticated])

    const theme = {
      global: {
        font: {
          family: 'Roboto',
          size: '18px',
          height: '40px'
        },
        colors: {
            brand: company.primaryColor,
            "accent-1": '#FFFFFF',
            focus: "transparent",
            gray: '#444444',
            black: '#292929'
        }
      }
    };

    const brandLogo = company?.logo ? `${process.env.REACT_APP_AWS_STATIC_PATH || 'https://s3.us-east-1.amazonaws.com/com.technolica.dev/'}${company?.logo}?_ts=${Date.now()}`:`${process.env.REACT_APP_PUBLIC_URL}/ZoneTap.svg`;
    const featuresVisibility = company?.featuresVisibility;
    return (
        <Grommet theme={theme}>
            <Grid fill columns={["260px", "auto"]}>
                <Sidebar background="gray" pad="large">
                    <Nav gap="small">
                        <Grid align="center" columns={["35px", "auto"]}>
                            <Dashboard color="accent-1" /> <AnchorLink weight="normal" to="/dashboard" label={lbl("menus.dashboard")} />
                        </Grid>
                        <h3>{lbl("menus.setup")}</h3>
                        {
                            featuresVisibility?.setupDevices ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Device color="accent-1" /> <AnchorLink weight="normal" to="/devices" label={lbl("menus.devices")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.workSites ?
                            (
                                <Grid align="center" columns={["35px", "auto"]} >
                                    <MapLocation color="accent-1" /> <AnchorLink weight="normal" to="/work-sites" label={lbl("menus.work_sites")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.workZones ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Select color="accent-1" /> <AnchorLink weight="normal" to="/static-zones" label={lbl("menus.work_zones")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.overlays ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Navigate color="accent-1" /> <AnchorLink weight="normal" to="/overlays" label={lbl("menus.overlays")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.settings ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Organization color="accent-1" /> <AnchorLink weight="normal" to="/company/settings" label={lbl("menus.settings")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            /*
                             <Grid align="center" columns={["35px", "auto"]}>
                                <Navigate color="accent-1" /> <AnchorLink weight="normal" to="/kml-zones" label="KML Zones" />
                            </Grid>
                             */
                        }
                        <h3>{lbl("menus.reporting")}</h3>
                        {
                            featuresVisibility?.realtimeMap ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Map color="accent-1" /> <AnchorLink weight="normal" to="/maps" label ={lbl("menus.reportings_realtime_map")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.replay ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Map color="accent-1" /> <AnchorLink weight="normal" to="/maps/replays" label ={lbl("menus.reportings_replay")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.devicePath ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Map color="accent-1" /> <AnchorLink weight="normal" to="/maps/replay-static-line" label ={lbl("menus.reportings_device_path")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.alerts ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Alert color="accent-1" /> <AnchorLink weight="normal" to="/alerts" label={lbl("menus.reportings_alerts")} />
                                </Grid>
                            )
                            : null
                        }
                        {
                            featuresVisibility?.reportingDevices ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <Device color="accent-1" /> <AnchorLink weight="normal" to="/reports/devices" label={lbl("menus.reportings_devices")} />
                                </Grid>
                            )
                            : null
                        }
                         {
                            featuresVisibility?.reportingSiteVisitReport ?
                            (
                                <Grid align="center" columns={["35px", "auto"]}>
                                    <MapLocation color="accent-1" /> <AnchorLink weight="normal" to="/site-visit-report" label={lbl("menus.reportings_site_visit_report")} />
                                </Grid>
                            )
                            : null
                        }
                    </Nav>
                </Sidebar>
                <Main background="#f1f1f1" fill="vertical" pad="none">
                    <div style={{minHeight:'100vh'}}>
                        <img style={{marginTop: '35px', marginLeft: '35px', maxHeight: '65px', width: 'auto'}} alt="Zonetap" src={brandLogo} />
                        <Menu icon={<SettingsOption color="brand" />} style={{float: "right"}} items={[{label: "User Information"}, {label: "logout", onClick: () =>{
                          localStorage.removeItem('staticZonePageSelectedWorkSite')
                          logout({ returnTo: process.env.REACT_APP_PUBLIC_URL })
                        }}]} />
                        <Menu icon={<Notification color="brand" />} style={{float: "right"}} items={[{label: "No new notifications", disabled: true}]} />
                        <hr style={{border: 0}} />
                        {children}
                    </div>
                </Main>
            </Grid>
        </Grommet>
    )
}

export default withAuthenticationRequired(connect(mapStateToProps)(MainLayout), {
    onRedirecting: () => (<AppLoader isLoading />)
});