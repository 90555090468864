import React from 'react';
import { Grommet } from 'grommet'

const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '40px'
    },
    colors: {
        brand: '#F35613',
        "accent-1": '#FFFFFF',
        focus: "transparent",
        gray: '#444444',
        black: '#292929',
    }
  }
};

const AuthLayout = ({ children }: any) => {
    return (
        <Grommet style={{ width: "360px", margin: "80px auto"}} theme={theme}>
            <img style={{ display: "block", margin: "60px auto"}} alt="Zonetap" src={`${process.env.REACT_APP_PUBLIC_URL}/ZoneTap.svg`} />
            <div>
                {children}
            </div>
        </Grommet>
    )
}

export default AuthLayout;