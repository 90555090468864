import {
    FETCH_DEVICES_REQUEST,
    FETCH_DEVICES_SUCCESS,
    FETCH_DEVICES_FAILED,
    FETCH_DEVICE_REQUEST,
    FETCH_DEVICE_SUCCESS,
    FETCH_DEVICE_FAILED,
    UPDATE_DEVICE_REQUEST,
    UPDATE_DEVICE_SUCCESS,
    UPDATE_DEVICE_FAILED,
    FETCH_KML_REQUEST,
    FETCH_KML_SUCCESS,
    FETCH_KML_FAILED,
    FETCH_REPLAY_DATES_REQUEST,
    FETCH_REPLAY_DATES_SUCCESS,
    FETCH_REPLAY_DATES_FAILED,
    FETCH_REPLAYS_REQUEST,
    FETCH_REPLAYS_SUCCESS,
    FETCH_REPLAYS_FAILED,
    FETCH_REPLAY_STATIC_REQUEST,
    FETCH_REPLAY_STATIC_SUCCESS,
    FETCH_REPLAY_STATIC_FAILED,
    FETCH_REPLAY_STATIC_DATES_REQUEST,
    FETCH_REPLAY_STATIC_DATES_SUCCESS,
    FETCH_REPLAY_STATIC_DATES_FAILED,
    SET_DEVICE_STATE
} from './types'

const initialState = {
    items: [],
    item: null,
    loading: false,
    error: null,
    kmlItems: [],
    replays: [],
    replayDates: [],
    staticReplayDates: [],
    replay_static: null,
    updated: null,
    showPopup:false
}

interface IAction {
    type: String,
    payload: any
}

const deviceReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_DEVICES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case FETCH_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            }

        case FETCH_DEVICES_FAILED:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload
            }

        case FETCH_DEVICE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case FETCH_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
            }

        case FETCH_DEVICE_FAILED:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload
            }
        
        case UPDATE_DEVICE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updated: null,
            }

        case UPDATE_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                updated: action.payload,           
            }

        case UPDATE_DEVICE_FAILED:
            return {
                ...state,
                loading: false,
                updated: null,
                error: action.payload
            }
        
        case FETCH_KML_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case FETCH_KML_SUCCESS:
            return {
                ...state,
                loading: false,
                kmlItems: action.payload,
            }

        case FETCH_KML_FAILED:
            return {
                ...state,
                loading: false,
                kmlItems: [],
                error: action.payload
            }

        case FETCH_REPLAY_DATES_REQUEST:
            return {
              ...state,
              loading: true,
              error: null,
              replayDates: []
            }

        case FETCH_REPLAY_DATES_SUCCESS:
            return {
              ...state,
              loading: false,
              replayDates: action.payload
            }
        
        case FETCH_REPLAY_DATES_FAILED: 
            return {
              ...state,
              loading: false,
              error: action.payload
            }
        

        case FETCH_REPLAYS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                replays: [],
                showPopup:true
            }

        case FETCH_REPLAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                replays: action.payload,
            }

        case FETCH_REPLAYS_FAILED:
            return {
                ...state,
                loading: false,
                replays: [],
                error: action.payload,
                showPopup:true
            }
        case FETCH_REPLAY_STATIC_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                replay_static: null
            }

        case FETCH_REPLAY_STATIC_SUCCESS:
            return {
                ...state,
                loading: false,
                replay_static: action.payload,
            }

        case FETCH_REPLAY_STATIC_FAILED:
            return {
                ...state,
                loading: false,
                replay_static: null,
                error: action.payload
            }

        case FETCH_REPLAY_STATIC_DATES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                staticReplayDates: []
            }
        
        case FETCH_REPLAY_STATIC_DATES_SUCCESS: 
            return {
              ...state,
              loading: false,
              staticReplayDates: action.payload
            }

        case FETCH_REPLAY_STATIC_DATES_FAILED:
            return  {
              ...state,
              loading: false,
              error: action.payload
            }

        
        case SET_DEVICE_STATE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default deviceReducer;