import { all, takeEvery, put, call } from 'redux-saga/effects'

import {
    fetchStaticAlerts,
    fetchStaticAlert,
    fetchDeviceAlerts,
    fetchDeviceAlert,
    fetchDeviceAccuracyAlerts,
    fetchDeviceAccuracyAlert,
    fetchIdleDeviceAlerts,
    fetchIdleDeviceAlert
} from '../../services/alertService';

import {
    FETCH_STATIC_ALERTS_REQUEST,
    FETCH_STATIC_ALERTS_SUCCESS,
    FETCH_STATIC_ALERTS_FAILED,
    
    FETCH_STATIC_ALERT_REQUEST,
    FETCH_STATIC_ALERT_SUCCESS,
    FETCH_STATIC_ALERT_FAILED,
    
    FETCH_DEVICE_ALERTS_REQUEST,
    FETCH_DEVICE_ALERTS_SUCCESS,
    FETCH_DEVICE_ALERTS_FAILED,

    FETCH_DEVICE_ALERT_REQUEST,
    FETCH_DEVICE_ALERT_SUCCESS,
    FETCH_DEVICE_ALERT_FAILED,

    FETCH_DEVICE_ACCURACY_ALERTS_REQUEST,
    FETCH_DEVICE_ACCURACY_ALERTS_SUCCESS,
    FETCH_DEVICE_ACCURACY_ALERTS_FAILED,

    FETCH_DEVICE_ACCURACY_ALERT_REQUEST,
    FETCH_DEVICE_ACCURACY_ALERT_SUCCESS,
    FETCH_DEVICE_ACCURACY_ALERT_FAILED,

    FETCH_IDLE_DEVICE_ALERTS_REQUEST,
    FETCH_IDLE_DEVICE_ALERTS_SUCCESS,
    FETCH_IDLE_DEVICE_ALERTS_FAILED,

    FETCH_IDLE_DEVICE_ALERT_REQUEST,
    FETCH_IDLE_DEVICE_ALERT_SUCCESS,
    FETCH_IDLE_DEVICE_ALERT_FAILED
} from './types';

export function* fetchStaticAlertsSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchStaticAlerts, payload)
        if(result){
            yield put({ type: FETCH_STATIC_ALERTS_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_STATIC_ALERTS_FAILED, payload: error })
    }
}

export function* fetchStaticAlertSaga({payload}: any):Generator<any> {
    try {
        const result = yield call(fetchStaticAlert, payload.id)
        if(result){
            yield put({ type: FETCH_STATIC_ALERT_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_STATIC_ALERT_FAILED, payload: error })
    }
}

export function* fetchDeviceAlertsSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchDeviceAlerts, payload)
        if(result){
            yield put({ type: FETCH_DEVICE_ALERTS_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_DEVICE_ALERTS_FAILED, payload: error })
    }
}

export function* fetchIdleDeviceAlertsSaga({payload}: any): Generator<any> {
  try {
      const result = yield call(fetchIdleDeviceAlerts, payload)
      if(result){
          yield put({ type: FETCH_IDLE_DEVICE_ALERTS_SUCCESS, payload: result })
      }
  } catch (error) {
      yield put({ type: FETCH_IDLE_DEVICE_ALERTS_FAILED, payload: error })
  }
}

export function* fetchDeviceAlertSaga({payload}: any):Generator<any> {
    try {
        const result = yield call(fetchDeviceAlert, payload.id)
        if(result){
            yield put({ type: FETCH_DEVICE_ALERT_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_DEVICE_ALERT_FAILED, payload: error })
    }
}

export function* fetchDeviceAccuracyAlertsSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(fetchDeviceAccuracyAlerts, payload)
        if(result){
            yield put({ type: FETCH_DEVICE_ACCURACY_ALERTS_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_DEVICE_ACCURACY_ALERTS_FAILED, payload: error })
    }
}

export function* fetchDeviceAccuracyAlertSaga({payload}: any):Generator<any> {
    try {
        const result = yield call(fetchDeviceAccuracyAlert, payload.id)
        if(result){
            yield put({ type: FETCH_DEVICE_ACCURACY_ALERT_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_DEVICE_ACCURACY_ALERT_FAILED, payload: error })
    }
}

export function* fetchIdleDeviceAlertSaga({payload}: any):Generator<any> {
  try {
      const result = yield call(fetchIdleDeviceAlert, payload.id)
      if(result){
          yield put({ type: FETCH_IDLE_DEVICE_ALERT_SUCCESS, payload: result })
      }
  } catch (error) {
      yield put({ type: FETCH_IDLE_DEVICE_ALERT_FAILED, payload: error })
  }
}

export default function* deviceSagas(){
    yield all([
        takeEvery(FETCH_STATIC_ALERTS_REQUEST, fetchStaticAlertsSaga),
        takeEvery(FETCH_STATIC_ALERT_REQUEST, fetchStaticAlertSaga),
        takeEvery(FETCH_DEVICE_ALERTS_REQUEST, fetchDeviceAlertsSaga),
        takeEvery(FETCH_DEVICE_ALERT_REQUEST, fetchDeviceAlertSaga),
        takeEvery(FETCH_DEVICE_ACCURACY_ALERTS_REQUEST, fetchDeviceAccuracyAlertsSaga),
        takeEvery(FETCH_DEVICE_ACCURACY_ALERT_REQUEST, fetchDeviceAccuracyAlertSaga),
        takeEvery(FETCH_IDLE_DEVICE_ALERTS_REQUEST, fetchIdleDeviceAlertsSaga),
        takeEvery(FETCH_IDLE_DEVICE_ALERT_REQUEST, fetchIdleDeviceAlertSaga),
    ])
}
