import React, { Fragment, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth0 } from "@auth0/auth0-react";
import store from 'store';

import AppLoader from '../AppLoader';
import MainLayout from './Main';
import AuthLayout from './Auth';

let previousPath = ''

const Layouts = {
    auth: AuthLayout,
    main: MainLayout,
    public: MainLayout
}

const Layout = ({
    children,
    location: { pathname, search }
}: any) => {
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

    const currentPath = pathname + search;
    if (currentPath !== previousPath) {
        window.scrollTo(0, 0)
    }
    previousPath = currentPath;
    
    useEffect(() => {
        if(isAuthenticated){
            (async() => {
                const pau = { audience: process.env.REACT_APP_AUTH0_SERVICE, scope: 'read:devices' };
                const accessToken = await getAccessTokenSilently(pau);
                store.set('accessToken', accessToken);
            })()   
        }
    }, [getAccessTokenSilently, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            store.set('companyId', user?.companyId);
            store.set('workSites', user?.workSites);
        }
    }, [user, isAuthenticated]);

    //Get layout type
    const getLayoutType = () => {
        if (pathname === '/') {
          return 'public'
        }
        if (/^\/auth(?=\/|$)/i.test(pathname)) {
            return 'auth'
        }
        return 'main'
    }

    //Future refs
    const ContainerLayout = Layouts[getLayoutType()]
    const isUserAuthorized = true;
    const isUserLoading = false;
    const isAuthLayout = getLayoutType() === 'auth'

    const BootstrappedLayout = () => {
        // Show loader when user in check authorization process, not authorized yet and not on login pages
        if (isUserLoading && !isUserAuthorized) { //&& !isAuthLayout
            return <AppLoader isLoading={true} />
        }

        // redirect to login page if current is not login page and user not authorized
        if (!isAuthLayout && !isUserAuthorized) {
            return <Redirect to="/auth/login" />
        }

        // in other case render previously set layout
        return <ContainerLayout>{children}</ContainerLayout>
    }

    return (
        <Fragment>
            <Helmet titleTemplate="%s | Zonetap" title="Zonetap Portal" />
            {BootstrappedLayout()}
        </Fragment>
    )
}

export default withRouter(Layout)