import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
    FETCH_KML_LIST_REQUEST,
    FETCH_KML_LIST_SUCCESS,
    FETCH_KML_LIST_FAILED,
    FETCH_KML_REQUEST,
    FETCH_KML_SUCCESS,
    FETCH_KML_FAILED,
    CREATE_KML_REQUEST,
    CREATE_KML_SUCCESS,
    CREATE_KML_FAILED,
    UPDATE_KML_REQUEST,
    UPDATE_KML_SUCCESS,
    UPDATE_KML_FAILED,
    DELETE_KML_REQUEST,
    DELETE_KML_SUCCESS,
    DELETE_KML_FAILED,
    UPLOAD_KML_FILE_REQUEST,
    UPLOAD_KML_FILE_SUCCESS,
    UPLOAD_KML_FILE_FAILED,
    BULK_DELETE_KML_REQUEST,
    BULK_DELETE_KML_SUCCESS,
    BULK_DELETE_KML_FAILED
} from './types';
import {
    fetchZones,
    fetchZone,
    createZone,
    updateZone,
    deleteZone,
    uploadKmlOrKmz,
    bulkDeleteKml
} from '../../services/kmlService';

export function* fetchKMLsSaga({payload}: any): Generator<any> {
    try {
        const result:any = yield call(fetchZones, payload)
        if(result){
            yield put({ type: FETCH_KML_LIST_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_KML_LIST_FAILED, payload: error })
    }
}

export function* fetchKMLSaga({payload}: any): Generator<any> {
    try {
        const { KMLId } = payload;
        const result = yield call(fetchZone, KMLId)
        if(result){
            yield put({ type: FETCH_KML_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_KML_FAILED, payload: error })
    }
}

export function* createKMLSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(createZone, payload)
        if(result){
            yield put({ type: CREATE_KML_SUCCESS, payload: result })
            yield put({ type: FETCH_KML_LIST_REQUEST, payload: {} })
        }
    } catch (error) {
        yield put({ type: CREATE_KML_FAILED, payload: error })
    }
}

export function* updateKMLSaga({payload}: any): Generator<any> {
    try {
        const { id, data } = payload;
        const result = yield call(updateZone, id, data)
        if(result){
            yield put({ type: UPDATE_KML_SUCCESS, payload: result })
            //yield put({ type: FETCH_KML_LIST_REQUEST, payload: {} })
        }
    } catch (error) {
        yield put({ type: UPDATE_KML_FAILED, payload: error })
    }
}

export function* deleteKMLSaga({payload}: any):Generator<any> {
    try {
        const { id } = payload;
        const result = yield call(deleteZone, id)
        yield put({ type: DELETE_KML_SUCCESS, payload: result })
        yield put({ type: FETCH_KML_LIST_REQUEST, payload: {} })
    } catch (error) {
        yield put({ type: DELETE_KML_FAILED, payload: error })
    }
}

export function* uploadKMLSaga({payload}: any): Generator<any> {
    try {
        const result = yield call(uploadKmlOrKmz, payload)
        if(result){
            yield put({ type: UPLOAD_KML_FILE_SUCCESS, payload: result })
            yield put({ type: FETCH_KML_LIST_REQUEST, payload: {} })
        }
    } catch (error) {
        yield put({ type: UPLOAD_KML_FILE_FAILED, payload: error })
    }
}

export function* bulkDeleteKMLSaga({payload}: any): Generator<any> {
  try {
      const result = yield call(bulkDeleteKml, payload)
      if(result){
          yield put({ type: BULK_DELETE_KML_SUCCESS, payload: result })
          yield put({ type: FETCH_KML_LIST_REQUEST, payload: {} })
      }
  } catch (error) {
      yield put({ type: BULK_DELETE_KML_FAILED, payload: error })
  }
}

export default function* deviceSagas(){
    yield all([
        takeEvery(FETCH_KML_LIST_REQUEST, fetchKMLsSaga),
        takeEvery(FETCH_KML_REQUEST, fetchKMLSaga),
        takeEvery(CREATE_KML_REQUEST, createKMLSaga),
        takeEvery(UPDATE_KML_REQUEST, updateKMLSaga),
        takeEvery(DELETE_KML_REQUEST, deleteKMLSaga),
        takeEvery(UPLOAD_KML_FILE_REQUEST, uploadKMLSaga),
        takeEvery(BULK_DELETE_KML_REQUEST, bulkDeleteKMLSaga)
    ])
}