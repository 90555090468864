import {
    FETCH_POI_LIST_REQUEST,
    FETCH_POI_LIST_SUCCESS,
    FETCH_POI_LIST_FAILED,
    FETCH_POI_REQUEST,
    FETCH_POI_SUCCESS,
    FETCH_POI_FAILED,
    CREATE_POI_REQUEST,
    CREATE_POI_SUCCESS,
    CREATE_POI_FAILED,
    UPDATE_POI_REQUEST,
    UPDATE_POI_SUCCESS,
    UPDATE_POI_FAILED,
    DELETE_POI_REQUEST,
    DELETE_POI_SUCCESS,
    DELETE_POI_FAILED
} from './types';

const initialState = {
    loading: false,
    error: null,
    items: [],
    item: null,
    deletedItem: null
}

interface IAction {
    type: String,
    payload: any
}

const poiReducer = ( state = initialState, action: IAction ) => {
    switch (action.type) {
        case FETCH_POI_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                items: [],
                newItem: null,
                updatedItem: null
            }
        
        case FETCH_POI_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            }
        
        case FETCH_POI_LIST_FAILED:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload
            }
        
        case FETCH_POI_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                item: null,
            }
        
        case FETCH_POI_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
            }
        
        case FETCH_POI_FAILED:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload
            }
        
        case CREATE_POI_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                newItem: null,
            }
        
        case CREATE_POI_SUCCESS:
            return {
                ...state,
                loading: false,
                newItem: action.payload,
            }
        
        case CREATE_POI_FAILED:
            return {
                ...state,
                loading: false,
                newItem: null,
                error: action.payload
            }
        
        case UPDATE_POI_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updatedItem: null,
            }
        
        case UPDATE_POI_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedItem: action.payload,
            }
        
        case UPDATE_POI_FAILED:
            return {
                ...state,
                loading: false,
                updatedItem: null,
                error: action.payload
            }
        
        case DELETE_POI_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                deletedItem: null,
            }
        
        case DELETE_POI_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedItem: action.payload,
            }
        
        case DELETE_POI_FAILED:
            return {
                ...state,
                loading: false,
                deletedItem: null,
                error: action.payload
            }
        
        default:
            return state
    }
}

export default poiReducer;