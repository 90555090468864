import ApiClient from './ApiClient';

export const fetchAllDeviceReport = (params:any) => {
    return ApiClient.get(`reports/devices-time-spending`, {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchDeviceReport = (id:String, params:any) => {
  return ApiClient.get(`reports/device-time-spending/${id}`, {params})
      .then( response => {
          if(response){
              return response.data
          }
          return false
      })
}

export const fetchDeviceZoneReport = (id:String, params:any) => {
  return ApiClient.get(`reports/device-time-spending-by-zone/${id}`, {params})
      .then( response => {
          if(response){
              return response.data
          }
          return false
      })
}